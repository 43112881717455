import React, { useState  ,useRef,useEffect} from "react";
import Sidebar from '../../components/sidebar/Sidebar';
import './edit.scss'
import { useParams,useNavigate   } from 'react-router-dom';
import {useForm} from '../hooks/useForm'
import uuid from 'react-uuid';
import { addEmployee, getEmployeeById,editEmployee } from '../employee/localstorage';
import Navbar from "../../components/navbar/Navbar";

export const Edit=()=>{
  const navigate = useNavigate();

  const { id } = useParams();
  const [isFormValid, setIsFormValid] = useState(false);

  
  const {inputValues,  handleInputChange, resetForm, setForm } = useForm({
    firstname: "",
    lastname: "",
    gstNumber: "",
    vstNumber:"",
    company:"",
    mobile: "",
    address: "",
    emergnecyContact: "",
    email: "",
  });

  useEffect(() => {
    if (id) {
        const employee = getEmployeeById(id);
        setForm(employee);
    }
}, [id]);




const handleSubmit = (event) => {
  event.preventDefault();
  id ? editEmployee(id, inputValues) : addEmployee({ id: uuid(), ...inputValues });
  resetForm();
 
  navigate('/distributor');
};

const validateForm = () => {
  const { firstname, lastname,company,mobile,address,emergnecyContact,email}=inputValues;
  return firstname.trim() !== '' && lastname.trim() !== '' && mobile.trim() !== '' &&   address.trim() !== '' &&   emergnecyContact.trim() !== '' &&   email.trim() !== '' && /\S+@\S+\.\S+/.test(email) ===true
};


React.useEffect(() => {
  setIsFormValid(validateForm());
}, [inputValues]);


  
  return(
    <>
    <div className="editMain">
    <Sidebar/>

    <div className="editBody">
    <Navbar/>
   
     <div className='edit-container'>
       <label>User Account Information:</label>
        <hr />
       <form className="editInfo" onSubmit={handleSubmit}>
         <div className='basic-info'>
          <label htmlFor="firstname" className="inputTitle">First Name *<br />
            <input type='text' name="firstname" placeholder='First Name  -' value={inputValues.firstname} onChange={handleInputChange} />
          </label>

          <label htmlFor="lastname"  className="inputTitle">Last Name *<br />
            <input type='text' name="lastname" placeholder='Last Name -'value={inputValues.lastname} onChange={handleInputChange}/>
          </label>

          <label htmlFor="company"  className="inputTitle">Company <br />
            <input type='text' name="company"  placeholder='Company-'value={inputValues.company} onChange={handleInputChange}/>
          </label>

          <label htmlFor="vatNumber"  className="inputTitle">VAT Number <br />
            <input type='text' name="vstNumber" placeholder='VAT Number  -' value={inputValues.vstNumber} onChange={handleInputChange} /> 
          </label>
             
          <label htmlFor="gstNumber"  className="inputTitle">GST Number <br />
            <input type='text' name="gstNumber" placeholder='GST Number -'value={inputValues.gstNumber} onChange={handleInputChange}/>
          </label>

          <label htmlFor="mobile"  className="inputTitle">Mobile Number *<br />
            <input type='text' name="mobile"placeholder='Mobile -'value={inputValues.mobile} onChange={handleInputChange}></input>
          </label>
          
          <label htmlFor="address"  className="inputTitle">Adress *<br />
            <input type='text' name='address' placeholder='Adress -'value={inputValues.address} onChange={handleInputChange}></input>
          </label>

          <label htmlFor="emergnecyContact"  className="inputTitle">Emergancey Contact Number <br />
            <input type='text' name="emergnecyContact" placeholder='Emergancey contact no -'value={inputValues.emergnecyContact} onChange={handleInputChange}></input>
          </label>  
           
          <label htmlFor="email"  className="inputTitle">Email *<br />
            <input type='email' name="email" placeholder='E-mail -'value={inputValues.email} onChange={handleInputChange}></input>
          </label>
 
         </div>

       <div className='edit-btn'>
         <button>Cancel</button>
         <button type="submit" className={`submitButton ${isFormValid ? "enabled" : ""}`} disabled={!isFormValid}>Save</button>
       </div>
       </form>
   </div>
   </div>
  </div>
    
    
    

</>
)
}