import React from 'react'
import './distributorsNew.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import {AiFillPlusCircle} from 'react-icons/ai'
import { useNavigate } from "react-router-dom"
import Navbar from '../../components/navbar/Navbar'
import { InventoryList } from './InventoryList';



export const Inventory=()=>{
    const navigate=useNavigate();

    function redirectEdit(event) {
        event.preventDefault();
        navigate(`/productedit`);
      }
     
  
  return(
  <>
    <div className="distributorMain">
      <Sidebar/>
      <div className="distributorBody">
        <Navbar/>
   
          <div className='body'>
              <div className='search-group'>     
                  <AiFillPlusCircle className='plusIcon-two'/><button className="add-btn" onClick={redirectEdit}>Add New Tyre</button>
                  <div className='h-line'></div>
                </div>
            <InventoryList/>
          
          </div>
      </div>
      
    </div>
       
  </>
  )
}