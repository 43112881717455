import { useEffect, useState } from 'react';
import { getListEmployees } from './localstorage';
import { EmployeeItem } from './EmployeeItem'


export const EmployeeList = () => {
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        setEmployees(getListEmployees());
    }, []);

    return (
       
        
        <table className='table-employee'>
                <thead>
                    <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Company</th>
                    <th>VAT Number</th>
                    <th>GST Number</th>
                    <th>Mobile Number</th>
                    <th>Adress</th>
                    <th>Email</th>
                    <th>Actions</th>
                </tr>
                 </thead>
                 <tbody>
                                {
                                    employees.map(employee => <EmployeeItem employee={employee} key={employee.id} setEmployees={setEmployees} />)
                                }
                    </tbody>
            </table>
       
    )
}