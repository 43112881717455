import React from 'react'
import './login.scss'
import { useNavigate } from 'react-router-dom'

function Login() {
    const navigate = useNavigate();

    const navigateHome=()=>{
        navigate('./home')
    }
  return (
    <>
        <div className='login'>
            <div className="login-container">
                <p>Login</p>

                <form action="" className='loginForm'>
                    <input type="text" placeholder='User Name'/>
                    <input type="text" placeholder='Password'/>

                    <button onClick={navigateHome}>Submit</button>
                </form>
             </div>
         </div>
    </>
   
  )
}

export default Login