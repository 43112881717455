export const getListEmployees = () => {
    if (!localStorage["employees"]) {
      localStorage["employees"] = "[]";
    }
  
    let employees = localStorage["employees"];
    employees = JSON.parse(employees);
    return employees;
  };
  
  export const addEmployee = (employee) => {
    const employees = getListEmployees();
    employees.push(employee);
    localStorage["employees"] = JSON.stringify(employees);
  };
  
  export const removeEmployee = (id) => {
    let employees = getListEmployees();
    employees = employees.filter((employee) => employee.id !== id);
    localStorage["employees"] = JSON.stringify(employees);
  };
  
  export const getEmployeeById = (id) => {
    const employees = getListEmployees();
    const employee = employees.find((employee) => employee.id === id);
    return employee;
  };
  
  export const editEmployee = (id, newEmployee) => {
    let employees = getListEmployees();
    employees = employees.filter((employee) => employee.id !== id);
    employees.push(newEmployee);
    localStorage["employees"] = JSON.stringify(employees);
  };

  //products

  export const getListInventoryItems = () => {
    if (!localStorage["inventoryItems"]) {
      localStorage["inventoryItems"] = "[]";
    }
  
    let inventoryItems = localStorage["inventoryItems"];
    inventoryItems = JSON.parse(inventoryItems);
    return inventoryItems;
  };
  
  export const addInventoryItem = (item) => {
    const inventoryItems = getListInventoryItems();
    inventoryItems.push(item);
    localStorage["inventoryItems"] = JSON.stringify(inventoryItems);
  };
  
  export const removeInventoryItem = (id) => {
    let inventoryItems = getListInventoryItems();
    inventoryItems = inventoryItems.filter((item) => item.id !== id);
    localStorage["inventoryItems"] = JSON.stringify(inventoryItems);
  };
  
  export const getInventoryItemById = (id) => {
    const inventoryItems = getListInventoryItems();
    const item = inventoryItems.find((item) => item.id === id);
    return item;
  };
  
  export const editInventoryItem = (id, newItem) => {
    let inventoryItems = getListInventoryItems();
    inventoryItems = inventoryItems.filter((item) => item.id !== id);
    inventoryItems.push(newItem);
    localStorage["inventoryItems"] = JSON.stringify(inventoryItems);
  };
  