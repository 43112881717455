import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import './cost.scss';
import { getListInventoryItems } from './localstorage';

function Cost() {
  const [productCodes, setProductCodes] = useState([]);
  const [selectedProductCode, setSelectedProductCode] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [displaySummary, setDisplaySummary] = useState(false); 
  const [calculatedValues, setCalculatedValues] = useState(null); 

  useEffect(() => { 
    const inventoryItems = getListInventoryItems();
    const uniqueProductCodes = [...new Set(inventoryItems.map((inventoryItem) => inventoryItem.productCode))];
    setProductCodes(uniqueProductCodes);
  }, []);

  const handleProductCodeChange = (event) => {
    const code = event.target.value;
    setSelectedProductCode(code);
    const selectedProduct = getListInventoryItems().find((inventoryItem) => inventoryItem.productCode === code);
    setSelectedProduct(selectedProduct);
  };

  const handleCalculate = (event) => {
    event.preventDefault(); 
  
    const laborCharges = parseFloat(event.target.elements.laborCharges.value);
    const shipmentCharges = parseFloat(event.target.elements.shipmentCharges.value);
    const customClearance = parseFloat(event.target.elements.customClearance.value);
    const totalWeightShipment = parseFloat(event.target.elements.totalWeightShipment.value);
    const otherCharges = parseFloat(event.target.elements.otherCharges.value);

    const costPerTyre = (laborCharges + customClearance) / totalWeightShipment;
    const costPerShipment = costPerTyre * totalWeightShipment + otherCharges;

    
    setCalculatedValues({
      laborCharges,
      shipmentCharges,
      customClearance,
      totalWeightShipment,
      otherCharges,
      costPerTyre,
      costPerShipment,
    });

    
    setDisplaySummary(true);
  };

  return (
    <>
      <div className="costMain">
        <Sidebar />
        <div className="costBody">
          <Navbar />

          <label className='main-label'>Cost Calculator</label>
          <div className="left-container">
            <form action="" className='costForm' onSubmit={handleCalculate}>
              <label htmlFor="cost" className='cost-label'>Product Code
                <select name="" className="select" id='cost' onChange={handleProductCodeChange} value={selectedProductCode}>
                  <option value="">Select Product Code</option>
                  {productCodes.map((code, index) => (
                    <option key={index} value={code}>{code}</option>
                  ))}
                </select>
              </label>

              <label htmlFor="" className='cost-label'>Tyre Name
                <input type="text" value={selectedProduct ? selectedProduct.tyreName : ''} readOnly />
              </label>

              <label htmlFor="" className='cost-label'>Per Tyre Weight(Kg)
                <input type="text" value={selectedProduct ? selectedProduct.weight : ''} readOnly />
              </label>

              <label htmlFor="" className='cost-label'>Tyre Size
                <input type="text" value={selectedProduct ? selectedProduct.size : ''} readOnly />
              </label>

              <label htmlFor="" className='cost-label'>Laber Charges(Lkr)
                <input type="text" placeholder='Labor Charges' name='laborCharges' />
              </label>

              <label htmlFor="" className='cost-label'>Shipment Charges(Lkr)
                <input type="text" placeholder='Shipment Charges' name='shipmentCharges' />
              </label>

              <label htmlFor="" className='cost-label'>Custom Clearance(Lkr)
                <input type="text" placeholder='Custom Clearance' name='customClearance' />
              </label>

              <label htmlFor="" className='cost-label'>Total Weight Shipment(Lkr)
                <input type="number" placeholder='Weight Shipment' name='totalWeightShipment' />
              </label>

              <label htmlFor="" className='cost-label'>Other Charges(Lkr)
                <input type="number" placeholder='Other Charges' name='otherCharges' />
              </label>

              <label htmlFor="" className='cost-label'>Duty Charges
                <input type="number" placeholder='Duty Charges' name='dutyCharges' />
              </label>

              <label htmlFor="" className='cost-label'>Custom Field 1
                <input type="number" name='customOne' placeholder='Custom Field One' />
              </label>

              <label htmlFor="" className='cost-label'>Custom Field 2
                <input type="number" name='customTwo' placeholder='Custom Field Two' />
              </label>

              <button type="submit">Calculate</button>
            </form>
          </div>
          <div className="right-container">
            <div className="right-header">
              <p>Cost Summary</p>
            </div>

            <div className="summaryCost">
              <label htmlFor="">Labor Charges(Lkr)
                <p>{displaySummary && calculatedValues ? calculatedValues.laborCharges.toFixed(2) : '0.00'}</p>
              </label>

              <label htmlFor="">Shipment Charges(Lkr)
                <p>{displaySummary && calculatedValues ? calculatedValues.shipmentCharges.toFixed(2) : '0.00'}</p>
              </label>

              <label htmlFor="">Custom Clearance(Lkr)
                <p>{displaySummary && calculatedValues ? calculatedValues.customClearance.toFixed(2) : '0.00'}</p>
              </label>

              <label htmlFor="">Weight Shipment(Lkr)
                <p>{displaySummary && calculatedValues ? calculatedValues.totalWeightShipment.toFixed(2) : '0.00'}</p>
              </label>

              <label htmlFor="">Other Charges(Lkr)
                <p>{displaySummary && calculatedValues ? calculatedValues.otherCharges.toFixed(2) : '0.00'}</p>
              </label>

              <label htmlFor="">Cost Per Tyre(Lkr)
                <p>{displaySummary && calculatedValues ? calculatedValues.costPerTyre.toFixed(2) : '0.00'}</p>
              </label>

              <label htmlFor="">Cost Per Shipment(Lkr)
                <p>{displaySummary && calculatedValues ? calculatedValues.costPerShipment.toFixed(2) : '0.00'}</p>
              </label>

              <label htmlFor="" className='amount-label'>Cost Amount(Lkr)
                <p className='amount'>{displaySummary && calculatedValues ? calculatedValues.costPerShipment.toFixed(2) : '0.00'}</p>
              </label>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cost;
