import React from 'react'
import './distributorsNew.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import {AiFillPlusCircle} from 'react-icons/ai'
import { useNavigate } from "react-router-dom"
import { EmployeeList } from './EmployeeList'
import Navbar from '../../components/navbar/Navbar'


export const Employee=()=>{
    const navigate=useNavigate();

    function redirectEdit(event) {
        event.preventDefault();
        navigate(`/edit`);
      }
  
  return(
  <>
    <div className="distributorMain">
      <Sidebar/>
      <div className="distributorBody">
        <Navbar/>
   
          <div className='body'>
              <div className='search-group'>     
                  <AiFillPlusCircle className='plusIcon'/><button className="add-btn" onClick={redirectEdit}>Add New Distributor</button>
                  <div className='h-line'></div>
                </div>
            <EmployeeList/>
          </div>
      </div>
      
    </div>
       
  </>
  )
}