import { useEffect, useState } from 'react';
import { InventoryItem } from './InventoryItem';
import { getListInventoryItems } from './localstorage';


export const InventoryList = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(getListInventoryItems());
    }, []);

    return (
       
        
        <table className='table-product'>
                <thead>
                    <tr>
                    <th>Product Code </th>
                    <th>Tyre Name</th>
                    <th>Tyre Brand</th>
                    <th>Tyre Weight</th>
                    <th>Tyre Size</th>
                    <th>Manufacture Date</th>
                    <th>Expiery Date</th>
                    <th>Actions</th>
                </tr>
                 </thead>
                 <tbody>
                                {
                                    items.map(item => <InventoryItem item={item} key={item.id} setItems={setItems} />)
                                }
                    </tbody>
            </table>
       
    )
}