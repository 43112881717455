import React from 'react'
import { getListEmployees} from './localstorage';
import {MdModeEditOutline} from 'react-icons/md'
import {AiFillDelete} from 'react-icons/ai'
import { removeEmployee } from './localstorage';
import { useNavigate } from "react-router-dom";


export const EmployeeItem = ({ employee, setEmployees }) => {
    const {id,firstname,lastname,company,vstNumber,gstNumber, email,mobile,address } = employee;
    const navigate=useNavigate();
  
    const deleteEmployee = () => {
        removeEmployee(id);
        setEmployees(getListEmployees());
   }

  function redirectEdit(event) {
    event.preventDefault();
    navigate(`/edit-details/${id}`)
  }
    return (
        <tr >
           
            <td>{firstname}</td>
            <td>{lastname}</td>
            <td>{company}</td>
            <td>{vstNumber}</td>
            <td>{gstNumber}</td>
            <td>{mobile}</td>
            <td>{address}</td>
            <td>{email}</td>
            
            <td className='action-icon'>
              <MdModeEditOutline className='icon' onClick={redirectEdit}/>
              <AiFillDelete className='icon'  onClick={() => deleteEmployee()}/>
              
               
            </td>
            
        </tr>
        
    )
}