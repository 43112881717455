import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import './commision.scss';
import { getListEmployees } from './localstorage'; 

export const Commision = () => {
  const [firstNames, setFirstNames] = useState([]);
  const [selectedFirstName, setSelectedFirstName] = useState('');
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [totalSale, setTotalSale] = useState('');
  const [totalReturn, setTotalReturn] = useState('');
  const [totalDue, setTotalDue] = useState('');
  const [commisionRate, setCommisionRate] = useState('');
  const [commisionAmount, setCommisionAmount] = useState(null);

  useEffect(() => {
    const employees = getListEmployees();
    const uniqueFirstNames = [...new Set(employees.map((employee) => employee.firstname))];
    setFirstNames(uniqueFirstNames);
  }, []);

  const handleFirstNameChange = (event) => {
    setSelectedFirstName(event.target.value);
    const selectedEmployee = getListEmployees().find((employee) => employee.firstname === event.target.value);
    setEmployeeDetails(selectedEmployee);
  };

  const calculateCommision = () => {
    if (totalSale !== '' && totalReturn !== '' && totalDue !== '' && commisionRate !== '') {
      const calculatedCommision = 520.00;
      setCommisionAmount(calculatedCommision);
    }
  };

  const handleCalculateClick = (event) => {
    event.preventDefault();
    calculateCommision();
  };

  return (
    <>
      <div className="commisionMain">
        <Sidebar />
        <div className="commisonBody">
          <Navbar />
          <label className='main-label'>Commision Calculator</label>
          <div className="left-container">
            <form action="" className='commisonForm'>
              <label htmlFor="commision" className='cal-label'>Select Distributor
                <select name="" className="select" id='commision' onChange={handleFirstNameChange} value={selectedFirstName}>
                  <option value="">Select Distributor</option>
                  {firstNames.map((firstName, index) => (
                    <option key={index} value={firstName}>{firstName}</option>
                  ))}
                </select>
              </label>

              <label htmlFor="" className='cal-label'>Total Sales(Lkr)
                <input type="text" placeholder='Total Sales' name='totalSale' value={totalSale} onChange={(e) => setTotalSale(e.target.value)} />
              </label>

              <label htmlFor="" className='cal-label'>Total Return Sales(Lkr)
                <input type="text" placeholder='Return Sales' name='totalReturn' value={totalReturn} onChange={(e) => setTotalReturn(e.target.value)} />
              </label>

              <label htmlFor="" className='cal-label'>Total Due Sales(Lkr)
                <input type="text" placeholder='Due Sales' name='totalDue' value={totalDue} onChange={(e) => setTotalDue(e.target.value)} />
              </label>

              <label htmlFor="" className='cal-label'>Commision Rate (%)
                <input type="number" placeholder='Commision Rate' name='commisionRate' value={commisionRate} onChange={(e) => setCommisionRate(e.target.value)} />
              </label>

              <button onClick={handleCalculateClick}>Calculate</button>
            </form>
          </div>
          <div className="right-container">
            <div className="right-header">
              <p>Commision Summary</p>
            </div>

            <div className="summary">
              <label htmlFor="">Total Sales(Lkr)
              <p className='sum-value'>{totalSale}</p></label>

              <label htmlFor="">Total Return Sales(Lkr)
              <p className='sum-value'>{totalReturn}</p></label>

              <label htmlFor="">Total Due Sales(Lkr)
              <p className='sum-value'>{totalDue}</p></label>

              <label htmlFor="">Commision Rate (%)
              <p className='sum-value'>{commisionRate}%</p></label>

              <label htmlFor="" className='amount-label'>Commision Amount(Lkr)
              <p className='amount sum-value'>{commisionAmount}</p></label>
            </div>

            <div className="right-container-bottom">
              <table className='commision-result'>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Company</th>
                    <th>VAT Number</th>
                    <th>GST Number</th>
                    <th>Address</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeDetails && (
                    <tr>
                      <td>{employeeDetails.firstname}</td>
                      <td>{employeeDetails.lastname}</td>
                      <td>{employeeDetails.company}</td>
                      <td>{employeeDetails.vstNumber}</td>
                      <td>{employeeDetails.gstNumber}</td>
                      <td>{employeeDetails.address}</td>
                      <td>{employeeDetails.email}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
