import Home from "./pages/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import {Employee} from './pages/employee/Employee';
import { Edit } from './pages/edit/Edit';
import { EmployeeItem } from './pages/employee/EmployeeItem';
import {Inventory} from './pages/employee/Inventory';
import ProductEdit from "./pages/edit/ProductEdit";
import {Commision} from "./pages/employee/Commision";
import Cost from "./pages/employee/Cost";
import  Login  from "./pages/login/Login"

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
        <Route  path='/' Component={Login} />
           <Route  path='/disributor' Component={Employee} />
           <Route  path='/edit' Component={Edit} />
           <Route  path='/productedit' Component={ProductEdit} />
           <Route  path='/productedit-edit/:id' Component={ProductEdit} />
           <Route  path='/edit-details/:id' Component={Edit} />
           <Route path='/employeeitem' Component={EmployeeItem}/>
           <Route path='/inventory' Component={Inventory}/>
           <Route path='/inventory/:id' Component={Inventory}/>
           <Route path='/commision' Component={Commision}/>
           <Route path='/cost' Component={Cost}/>
           <Route path='/home' Component={Home}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
