import React from 'react'
import { getListInventoryItems} from './localstorage';
import {MdModeEditOutline} from 'react-icons/md'
import {AiFillDelete} from 'react-icons/ai'
import { removeInventoryItem } from './localstorage';
import { useNavigate } from "react-router-dom";


export const InventoryItem = ({ item, setItems }) => {
    const {id,tyreName, brand,weight,productCode,mdate,edate,size} = item;
    const navigate=useNavigate();
   

    
    
    const deleteItem = () => {
      removeInventoryItem(id);
        setItems(getListInventoryItems());
   }

  function redirectEdit(event) {
    event.preventDefault();
    navigate(`/productedit-edit/${id}`)
  }

 
    return (
        <tr >
           <td>{productCode}</td>
            <td>{tyreName}</td>
            <td>{brand}</td>
            <td>{weight}</td>
            <td>{size}</td>
            <td>{mdate}</td>
            <td>{edate}</td>            
            <td className='action-icon'>
              <MdModeEditOutline className='icon' onClick={redirectEdit}/>
              <AiFillDelete className='icon'  onClick={() => deleteItem()}/>
              
               
            </td>
            
        </tr>
        
    )
}