import React, { useState  ,useRef,useEffect} from "react";
import { useParams,useNavigate   } from 'react-router-dom';
import {useForm} from '../hooks/useForm'
import uuid from 'react-uuid';
import { addInventoryItem, getInventoryItemById,editInventoryItem } from '../employee/localstorage';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import './edit.scss'


 const ProductEdit=()=>{
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFormValid, setIsFormValid] = useState(false);

  const {inputValues,  handleInputChange, resetForm, setForm } = useForm({
    tyreName:"",
    brand: "",
    size: "",
    weight: "",
    mdate: "",
    edate: "",
    productCode:"",
    category:"",
  });

  useEffect(() => {
    if (id) {
        const item = getInventoryItemById(id);
        setForm(item);
    }
}, [id]);




const handleSubmit = (event) => {
  event.preventDefault();
  id ? editInventoryItem(id, inputValues) : addInventoryItem({ id: uuid(), ...inputValues });
  resetForm();
 
  navigate('/inventory');
};

const validateForm = () => {
  const { tyreName, brand,size,weight,mdate,edate,productCode}=inputValues;
  return tyreName.trim() !== '' && brand.trim() !== '' && size.trim() !== '' && weight.trim() !== ''&&
  mdate.trim() !== '' &&  edate.trim() !== '' && productCode.trim() !== ''; 
};


React.useEffect(() => {
  setIsFormValid(validateForm());
}, [inputValues]);


  
  return(
    <>
   <div className="editMain">
    <Sidebar/>

    <div className="editBody">
        <Navbar/>
      <div className='edit-container'>
        <label>Tyre Information:</label>
        <hr />
        <form className="editInfoProduct" onSubmit={handleSubmit}>
          <div className='basic-tyre'>
            <label htmlFor="tyreName" className="inputTitle">Tyre Name *<br />
              <input type='text' name="tyreName" placeholder='Tyre Name  -' value={inputValues.tyreName} onChange={handleInputChange} />
            </label>

            <label htmlFor="brand" className="inputTitle">Tyre Brand *<br />
              <input type='text' name="brand" placeholder='Tyre Brand -'value={inputValues.brand} onChange={handleInputChange}/>
            </label>

            <label htmlFor="size" className="inputTitle">Tyre Size *<br />
              <input type='text' name="size" placeholder='Tyre size -'value={inputValues.size} onChange={handleInputChange}></input>
            </label>

            <label htmlFor="weight" className="inputTitle">Tyre Weight *<br />
              <input type='text' name="weight" placeholder='Tyre Weight -'value={inputValues.weight} onChange={handleInputChange}></input>
            </label>

            <label htmlFor="productCode" className="inputTitle">Product Code *<br />
              <input type="text" name="productCode" placeholder="Product Code" value={inputValues.productCode} onChange={handleInputChange}/>
            </label> 

            <label htmlFor="productCode" className="inputTitle">Category <br />
             <input type="text" name="category" placeholder="Category"  value={inputValues.category} onChange={handleInputChange}/>
            </label> 
              
            <label htmlFor="mdate" className="inputTitle">Manufacture Date *<br />
              <input type='date' name="mdate" placeholder='Manufacture date -'value={inputValues.mdate} onChange={handleInputChange}></input>
            </label>
            
            <label htmlFor="edate" className="inputTitle">Expiery Date *<br />
              <input type='date' name="edate" placeholder='Expiary date -'value={inputValues.edate} onChange={handleInputChange}></input>
            </label> 
          </div>

          <div className='edit-btn'>
            <button>Cancel</button>
            <button type="submit" className={`submitButton ${isFormValid ? "enabled" : ""}`} disabled={!isFormValid}>Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
   
 

</>
)
}
export default ProductEdit;